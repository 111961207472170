import React, { Component } from 'react';

class NotFoundPage extends Component {
    render() {
        return (
            <div>
                {/* TODO: Create NotFoundPage */}
            </div>
        );
    }
}

export default NotFoundPage;
